export default class FacturationList {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }

    bind() {
        var tauxTVAChange = false;
        var dateReglementChange = false;
        var uploadFileChange = false;
        var exportExcelFlag = false;

        $.fn.facturationList = {

            gridFacturation_onDataBound: function () {
                // Spécifique WebPack
                // On attends bien que Kendo ai chargé les templates pour relancer le binding des components
                window.Kernel.mountComponents($('#GridFacturation'));

                $.fn.facturationList.customKendoSearchBar();
                $.fn.facturationList.customKendoCommandButton();
                $.fn.facturationList.customEnveloppeCommandButton();
                $.fn.facturationList.checkEditCondition();
                $.fn.facturationList.autoColumnWidth();

                if ($("#EstCuisiniste").val() === "True") {
                    $(".k-grid-edit").attr('style', 'display:none');
                }
            },

            gridFacturation_Save: function (e) {

                if (tauxTVAChange) {
                    e.model.Taux_TVA = parseFloat($("#Taux_TVA").val());
                    tauxTVAChange = false;
                }

                if (dateReglementChange) {
                    e.model.DateReglementProxy = $("#dateReglementFacturation").val();
                    dateReglementChange = false;
                }

                if (uploadFileChange) {
                    $("#wipoz_projetFactureID").val(e.model.Wipoz_ProjetFactureID);
                    $("#form-upload-facture").submit();
                    uploadFileChange = false;
                }
            },

            gridFacturation_onRequestEnd: function (e) {
                if (e.type == "update") {
                    if (e.response == null || e.response.Error == null) {
                        toastr.success(Resource.toastrSuccessUpload);
                        $.fn.facturationList.gridFacturation_onDataBound();
                        $.fn.facturationList.refreshGridFacturation();
                    } else {
                        toastr.error(Resource.toastrErrorUpload);
                    }
                }
            },

            isFactureFileEditable: function (data) {
                if (data.ProjetFactureType == 2 || data.ProjetFactureType == 3) {
                    return false;
                }
                else {
                    return ! data.CheminFacture;
                }
            },

            isInvoiceNumberEditable: function (data) {
                return (data.ProjetFactureType == 1) && (data.InvoiceNumber == null || data.InvoiceNumber == '');
            },

            gridFacturation_ExcelExport: function (e) {
                if (!exportExcelFlag) {
                    e.sender.hideColumn("UploadFactureFile");
                    e.sender.hideColumn("TVAFacturation");
                    e.sender.showColumn("Taux_TVA");
                    e.sender.autoFitColumn("Taux_TVA");

                    e.preventDefault();
                    exportExcelFlag = true;
                    setTimeout(function () {
                        e.sender.saveAsExcel();
                    });

                } else {
                    e.sender.hideColumn("Taux_TVA");
                    e.sender.showColumn("TVAFacturation");
                    e.sender.showColumn("UploadFactureFile");
                    exportExcelFlag = false;
                }
            },

            successUploadFactureFile: function () {
                $.fn.facturationList.refreshGridFacturation();
                toastr.success(Resource.toastrSuccessUpload);
            },

            failureUploadFactureFile: function () {
                $.fn.facturationList.refreshGridFacturation();
                toastr.error(Resource.toastrErrorUpload);
            },

            gridFacturation_Edit: function () {
                $.fn.facturationList.customKendoEditInput();
                $.fn.facturationList.customKendoCommandButton();
                $.fn.facturationList.autoColumnWidth();
                $.fn.facturationList.setClickOnDdlEtatposeur();
            },

            gridFacturation_Cancel: function () {
                $.fn.facturationList.refreshGridFacturation();
                $.fn.facturationList.autoColumnWidth();
            },

            refreshGridFacturation: function () {
                $('#GridFacturation').data('kendoGrid').dataSource.read();
                $('#GridFacturation').data('kendoGrid').refresh();
            },

            autoColumnWidth: function () {
                var grid = $('#GridFacturation').data('kendoGrid');
                grid.autoFitColumn(0); //correspond à la colonne "line-edit-btn"
                //grid.autoFitColumn("EtatFacturationPoseur");
                //grid.autoFitColumn("TVAFacturation");
                grid.autoFitColumn("DateReglement");
                grid.autoFitColumn("UrlFacture");
            },

            
            checkEditCondition: function () {
                var grid = $("#GridFacturation").data("kendoGrid");
                $(".btn-edit").each(function (i, element) {
                    var tr = $(element).closest("tr");
                    var data = grid.dataItem(tr);
                    if (data.DateReglement && data.Status == 3) {
                        $(element).hide();
                    }
                    //var dateRegelement = $($(".dateReglement")[i]).text();
                    //var textEtatFacturationPoseur = $($(".etatFacturationPoseur")[i]).text();
                    //if (dateRegelement !== undefined && dateRegelement !== "" && textEtatFacturationPoseur === etatFacturationPoseur.etatPoseurReglee) {
                    //    var lineEditBtn = $($(".dateReglement")[i]).siblings(".line-edit-btn");
                    //    var editBtn = lineEditBtn.children(".k-grid-edit");
                    //    editBtn.css("display", "none");
                    //}
                });
            },

            setClickOnDdlEtatposeur: function () {
                var clickOnInputDdl = ".etatFacturationPoseur .k-dropdown .k-dropdown-wrap .k-input";
                var clickOnSelectDdl = ".etatFacturationPoseur .k-dropdown .k-dropdown-wrap .k-select";
                $(clickOnInputDdl).add(clickOnSelectDdl).click(function (e) {
                    $.fn.facturationList.editDropDownListEtatPoseur(e)
                })
            },

            editDropDownListEtatPoseur: function (e) {
                var dateDemandeReglement = $(e.target).parents(".etatFacturationPoseur").siblings(".dateDemandeReglement").text();
                var textEtatFacturationPoseur = $(e.target).parents(".etatFacturationPoseur").text();
                if (dateDemandeReglement !== "" && !textEtatFacturationPoseur.includes(etatFacturationPoseur.etatPoseurAFacturer)) {
                    var ddl = $("#EtatFacturationPoseur").data("kendoDropDownList");
                    var oldData = ddl.dataSource.data();

                    for (var i = 0; i < oldData.length; i++) {
                        if (oldData[i].Text === etatFacturationPoseur.etatPoseurAFacturer) {
                            ddl.dataSource.remove(oldData[i]);
                            ddl.select(0);
                        }
                    }
                }
            },

            customEnveloppeCommandButton: function () {
                var allDateDemandeReglement = $(".dateDemandeReglement");
                allDateDemandeReglement.each(function (i) {
                    var dateDemandeReglement = $($(".dateDemandeReglement")[i]).text();
                    if (dateDemandeReglement !== undefined && dateDemandeReglement !== "") {
                        var lineEditBtn = $($(".dateDemandeReglement")[i]).siblings(".line-edit-btn");
                        var sendBtn = lineEditBtn.children(".k-grid-mailToSend");

                        var iconBtn = $($(".send-demande-reglement")[i]);
                        var textEtatFacturationPoseur = $($(".etatFacturationPoseur")[i]).text();
                        var demandeRegelementEnvoyee = Resource.demandeRegelementEnvoyeeLe + dateDemandeReglement + " - ";
                        if (textEtatFacturationPoseur !== etatFacturationPoseur.etatPoseurReglee) {
                            sendBtn.toggleClass("k-grid-mailToSend k-grid-mailSentAndWaitForWipoz");
                            sendBtn.attr("title", demandeRegelementEnvoyee + Resource.waitForWipozCommandTitle);
                            iconBtn.toggleClass("btn-red-send btn-orange-send-disabled");
                        }
                        else {
                            sendBtn.toggleClass("k-grid-mailToSend k-grid-mailSentAndAllDone");
                            sendBtn.attr("title", demandeRegelementEnvoyee + Resource.factureRegleeCommandTitle);
                            iconBtn.toggleClass("btn-red-send btn-green-send-disabled");
                            iconBtn.toggleClass("fa-envelope-open-o fa-envelope-o");
                        }
                    }
                });
            },

            onEtatFacturationPoseurDatabound: function () { },
            onEtatFacturationWipozDatabound: function () { },

            onUploadFileChange: function (e) {
                var lineEditBtn = $(".k-grid-edit-row").children(".line-edit-btn");
                var uploadBtn = lineEditBtn.children(".k-grid-update");
                var error = e.files[0].validationErrors;
                if (error !== undefined && error.length > 0) {
                    uploadBtn.attr("disabled", "disabled")
                    uploadBtn.addClass("btn-save-disabled");
                    toastr.error(Resource.extensionFileError);
                } else if ($(".line-edit-btn.active.erreur") !== undefined) {
                    uploadBtn.removeAttr("disabled");
                    uploadBtn.removeClass("btn-save-disabled");
                }

                uploadFileChange = true;
            },

            onRemoveFile: function () {
                var lineEditBtn = $(".k-grid-edit-row").children(".line-edit-btn");
                var uploadBtn = lineEditBtn.children(".k-grid-update");
                uploadBtn.removeAttr("disabled");

                uploadFileChange = false;
            },

            onTVAChange: function (e) {
                tauxTVAChange = true;
                var dropdownlist = e.sender.element.data("kendoDropDownList");
                $("#Taux_TVA").val(dropdownlist.text());
            },

            onDatePickerChange: function () { dateReglementChange = true; },
            confirm: function (title, text) {
                return Swal.fire({
                    title: title,
                    text: text,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#2ABFBA',
                    cancelButtonColor: '#E0E0E0',
                    cancelButtonText: Resource.nonButtonText,
                    confirmButtonText: Resource.ouiButtonText,
                    closeOnConfirm: false,
                    allowOutsideClick: false
                });
            },

            customKendoCommandButton: function () {
                $.fn.facturationList.customActionCommandButton($(".k-grid-edit"), Resource.editCommandTitle);
                $.fn.facturationList.customActionCommandButton($(".k-grid-update"), Resource.updateCommandTitle);
                $.fn.facturationList.customActionCommandButton($(".k-grid-cancel"), Resource.cancelCommandTitle);
                $.fn.facturationList.customActionCommandButton($(".k-grid-mailToSend"), Resource.sendDemandeCommandTitle);
                $.fn.facturationList.customActionCommandButton($(".k-grid-mailSentAndWaitForWipoz"), Resource.waitForWipozCommandTitle);
                $.fn.facturationList.customActionCommandButton($(".k-grid-mailSentAndAllDone"), Resource.factureRegleeCommandTitle);
            },

            customActionCommandButton: function (selector, titleBtn) {
                selector.each(function (i) {
                    var span = $(selector[i]).children();
                    $(selector[i]).text("");
                    $(selector[i]).append(span);
                    $(selector[i]).attr("title", titleBtn);
                });
            },

            customKendoSearchBar: function () {
                if ($("#searchBar").val() === undefined) {
                    var gridSearch = $("span.k-grid-search");
                    $(gridSearch).wrap("<div id='searchBar' class='pull-right'></div>")
                    $("#searchBar").prepend("<span>" + Resource.rechercherTitle + ": </span");
                    $(".k-grid-search .k-input").addClass("filter-grid stringSearch form-control search-input searchi");
                    $(".k-grid-search .k-input").removeAttr("placeholder");
                    $(".k-grid-search .k-input").attr("title", Resource.rechercherParCuisinisteOuContremarque);
                }
            },

            customKendoEditInput: function () {
                var kendoInput = $("input.text-box");
                kendoInput.each(function (index) {
                    $(kendoInput[index]).addClass("form-control");
                });
            },

            sendDemandeReglement: function (e) {
                var factureDatePublication = $(e.target).parents(".line-edit-btn").siblings(".datePublicationFacture").text();
                if (factureDatePublication !== undefined && factureDatePublication !== "") {
                    $.fn.facturationList.confirm(Resource.demandeFacturationTitle, Resource.demandeFacturationText)
                        .then(result => {
                            if (result.value) {
                                $.fn.facturationList.ajaxPostDemandeDeReglement(e);
                            }
                            Swal.close();
                        });
                } else {
                    Swal.fire({
                        title: Resource.demandeFacturationTitleErreur,
                        text: Resource.demandeFacturationTextErreur,
                        type: "error",
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: true
                    });
                }
            },
            ValiderPaiement: function (id) {
                $.fn.facturationList.confirm(Resource.validerPaiementTitle, Resource.validerPaiementText)
                    .then(result => {
                        if (result.value) {
                            $.ajax({
                                url: urlAction.validerPaiement,
                                method: "POST",
                                contentType: "application/json; charset=utf-8",
                                dataType: "json",
                                data: JSON.stringify({
                                    projetFactureId: id,
                                }),
                                success: function () {
                                    $.fn.facturationList.refreshGridFacturation();
                                }
                            });
                        }
                        Swal.close();
                    });
            },

            

            ajaxPostDemandeDeReglement: function (e) {
                var projetFactureID = $(e.target).parents(".k-command-cell").siblings(".projetFactureID").text();

                $.ajax({
                    url: urlAction.sendDemandeReglementFacturation,
                    method: "POST",
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    data: JSON.stringify({
                        wipoz_ProjetFactureID: projetFactureID,
                    }),
                    success: function () {
                        toastr.success(Resource.toastrSuccessSendDemande);
                        $.fn.facturationList.gridFacturation_onDataBound();
                        $.fn.facturationList.refreshGridFacturation();
                    },
                    error: function () {
                        toastr.error(Resource.toastrErrorSendDemande);
                    }
                });
            }
        }
    }
}