export default class RightPanel {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $.fn.RightPanel = {
            selectProjet: function () {
                Swal.fire({
                    title: Resource.Patienter,
                    allowOutsideClick: () => !Swal.isLoading(),
                    onAfterClose: function () {
                        $(window).scrollTop(0);
                    }
                });
                Swal.showLoading();
                $.ajax({
                    url: $('#BoutonEngagement').data('url'),
                    method: $('#BoutonEngagement').data('method') ? $('#BoutonEngagement').data('method') : "POST",
                    data: $('#BoutonEngagement').data('parameters') ? $('#BoutonEngagement').data('parameters') : {},
                    success: function (data) {
                        Swal.close();
                        if (data.success === 1) {
                            let $modal = $("#ModalCustom");
                            let $btnConfirm = $modal.find('.modal-confirm');
                            $modal.find(".modal-dialog").addClass(data.stylelayout);
                            $modal.find(".modal-body").html(data.content);
                            window.Kernel.mountComponents($($modal));

                            $($modal).modal("show");
                            if ((data.confirmLabel != undefined) && (data.confirmLabel != '')) {
                                $modal.find(".modal-confirm").show();
                                //decharge les event click sur le bouton de confirmation
                                $btnConfirm.unbind("click");
                                //distribuer l'événement.
                                $btnConfirm.on("click", function () {
                                    $('#BoutonEngagement').trigger('modalAjaxConfirm');
                                });
                            }
                            else {
                                $modal.find(".modal-confirm").hide();
                            }
                        }
                        else if (data.success === 0){
                            Swal.fire({
                                title: Resource.ProjetNonDisponibleTitre,
                                text: Resource.ProjetNonDisponibleTexte,
                                type: 'warning',
                                confirmButtonColor: '#2ABFBA',
                                confirmButtonText: Resource.ProjetNonDisponibleRetour,
                                showLoaderOnConfirm: true
                            }).then((result) => {
                                window.location.href = "/Dashboard";
                            });
                        }
                    },
                    error: function (jqXHR) {
                        Swal.close();
                        if (jqXHR.status == 403) {
                            if (jqXHR.responseJSON.url) {
                                window.location.assign(jqXHR.responseJSON.url);
                            }
                            else if (jqXHR.responseJSON.message) {
                                toastr.error(jqXHR.responseJSON.message);
                            }
                        }
                        else {
                            toastr.error("Erreur");
                        }
                    }
                });
            }
        }
    };
}