import $ from 'jquery';
window.$ = $;
window.jQuery = $;

require('./scss/main.scss');

window._ = require('lodash');
import 'bootstrap';
import 'jquery-validation';
import 'jquery-ajax-unobtrusive';
import 'jquery-validation-unobtrusive';
import 'jquery-autocomplete';

import * as toastr from 'toastr';
window.toastr = toastr;

window.discardDatePickerInputText = function discardDatePickerInputText(event) {
    var x = event.charCode || event.keyCode;
    return (x === 9);
};
// Import du SweetAlerte
let Swal = require('sweetalert2');
window.Swal = Swal;

// Import de moment
let moment = require("moment");
if ("default" in moment) {
    moment = moment["default"];
}
window.moment = moment;

import LoginFormActions from "./js/components/LoginFormActions.js";
import RegisterFormActions from "./js/components/RegisterFormActions.js";
import FileRequiredFormActions from "./js/components/FileRequiredFormActions.js";
import ActiveButton from "./js/components/ActiveButton.js";
import CommonFormActions from "./js/components/CommonFormActions.js";
import DashboardFilters from "./js/components/DashboardFilters.js";
import ModalAjaxCustom from "./js/components/ModalAjaxCustom.js";
import ModalAjax from "./js/components/ModalAjax.js";
import ModalAjaxContent from "./js/components/ModalAjaxContent.js";
import FicheAlertsActions from "./js/components/FicheAlertsActions.js";
import MapHandler from "./js/components/MapHandler.js";
import Panel from "./js/components/Panel.js";
import DocumentManagement from "./js/components/DocumentManagement.js";
import CreationProjet from "./js/components/createOrEdit/CreationProjet.js";
import createOrEditDocument from "./js/components/createOrEdit/createOrEditDocument.js";
import createOrEditInfoGenerales from "./js/components/createOrEdit/createOrEditInfoGenerales.js";
import createOrEditInfoPlanningBudget from "./js/components/createOrEdit/createOrEditInfoPlanningBudget.js";
import createOrEditInfoTechniques from "./js/components/createOrEdit/createOrEditInfoTechniques.js";
import createOrEditManagement from "./js/components/createOrEdit/createOrEditManagement.js";
import AddressAutoComplete from "./js/components/AddressAutoComplete.js";
import PlanningActions from "./js/components/PlanningActions.js";
import CGU from "./js/components/CGU.js";
import ProjectList from "./js/components/ProjectList.js";
import Extensions from "./js/components/Extensions.js";
import PublishActions from "./js/components/PublishActions.js";
import Attribution from "./js/components/Attribution.js";
import RightPanel from "./js/components/RightPanel.js";
import TerminerProjet from "./js/components/TerminerProjet.js";
import TabHelperComponent from "./js/components/TabHelperComponent.js";
import ExternalCalendar from "./js/components/ExternalCalendar.js";
import FacturationList from "./js/components/FacturationList.js";
import ValidationUpdateProjet from "./js/components/ValidationUpdateProjet.js";
import Interessement from "./js/components/Interessement.js";
import CreationSAV from "./js/components/CreationSAV.js";
import MotifRefus from "./js/components/MotifRefus.js";
import SuspendreProjet from "./js/components/SuspendreProjet.js";
import DocumentsNumeriques from "./js/components/DocumentsNumeriques.js";
import CertificatFinTravaux from "./js/components/DocumentsNumeriques/CertificatFinTravaux.js";
import Inscription from "./js/components/Inscription.js";
import HomeTabDocument from "./js/components/HomeTabDocument.js";
import ToggleFavorite from "./js/components/ToggleFavorite.js";
import Discussion from "./js/components/Discussion.js";
import Alert from "./js/components/Alert.js";
import NumericTextBox from './js/components/NumericTextBox.js';
import NumericCheckBox from './js/components/NumericCheckBox.js';
import SelectTitleFromText from './js/components/SelectTitleFromText.js';
import Collaborateur from "./js/components/Collaborateur.js";
import Grid from "./js/components/Grid.js";
import SuggestedPrice from "./js/components/SuggestedPrice.js";
import TabNavigator from "./js/components/TabNavigator";
import Soldes from "./js/components/createOrEdit/Soldes";
import CBSkillSectionLink from "./js/components/CBSkillSectionLink";
import DecimalSkillSectionLink from "./js/components/DecimalSkillSectionLink";
import FileGrid from "./js/components/FileGrid";
import FileGridRemoveCommand from './js/components/FileGridRemoveCommand';
import FileGridEditCell from './js/components/FileGridEditCell';
import Uploader from './js/components/Uploader';
import SuiviLivraison from "./js/components/SuiviLivraison.js";
import ToggleDescription from "./js/components/ToggleDescription";
import Supplier from "./js/components/Supplier";
import KendoTabNavigator from "./js/components/KendoTabNavigator";
import MeasurementDone from "./js/components/MeasurementDone";
import ValidateMeasurement from "./js/components/ValidateMeasurement";
import ModalClose from "./js/components/ModalClose";

const Kernel = require('./js/utils/Kernel');

window.Kernel = new Kernel();
window.Kernel
    .registerComponent('DashboardFilters', DashboardFilters)
    .registerComponent('LoginFormActions', LoginFormActions)
    .registerComponent('CommonFormActions', CommonFormActions)
    .registerComponent('ModalAjaxCustom', ModalAjaxCustom)
    .registerComponent('ModalAjax', ModalAjax)
    .registerComponent('ModalAjaxContent', ModalAjaxContent)
    .registerComponent('FicheAlertsActions', FicheAlertsActions)
    .registerComponent('MapHandler', MapHandler)
    .registerComponent('Panel', Panel)
    .registerComponent('DocumentManagement', DocumentManagement)
    .registerComponent('CreationProjet', CreationProjet)
    .registerComponent('createOrEditDocument', createOrEditDocument)
    .registerComponent('createOrEditInfoGenerales', createOrEditInfoGenerales)
    .registerComponent('createOrEditInfoPlanningBudget', createOrEditInfoPlanningBudget)
    .registerComponent('createOrEditInfoTechniques', createOrEditInfoTechniques)
    .registerComponent('createOrEditManagement', createOrEditManagement)
    .registerComponent('AddressAutoComplete', AddressAutoComplete)
    .registerComponent('PlanningActions', PlanningActions)
    .registerComponent('CGU', CGU)
    .registerComponent('ProjectList', ProjectList)
    .registerComponent('Extensions', Extensions)
    .registerComponent('PublishActions', PublishActions)
    .registerComponent('RegisterFormActions', RegisterFormActions)
    .registerComponent('FileRequiredFormActions', FileRequiredFormActions)
    .registerComponent('Attribution', Attribution)
    .registerComponent('RightPanel', RightPanel)
    .registerComponent('TerminerProjet', TerminerProjet)
    .registerComponent('TabHelperComponent', TabHelperComponent)
    .registerComponent('ExternalCalendar', ExternalCalendar)
    .registerComponent('FacturationList', FacturationList)
    .registerComponent('ValidationUpdateProjet', ValidationUpdateProjet)
    .registerComponent('Interessement', Interessement)
    .registerComponent('CreationSAV', CreationSAV)
    .registerComponent('MotifRefus', MotifRefus)
    .registerComponent('SuspendreProjet', SuspendreProjet)
    .registerComponent('DocumentsNumeriques', DocumentsNumeriques)
    .registerComponent('CertificatFinTravaux', CertificatFinTravaux)
    .registerComponent('Inscription', Inscription)
    .registerComponent('HomeTabDocument', HomeTabDocument)
    .registerComponent('ToggleFavorite', ToggleFavorite)
    .registerComponent('Discussion', Discussion)
    .registerComponent('Alert', Alert)
    .registerComponent('Collaborateur', Collaborateur)
    .registerComponent('Grid', Grid)
    .registerComponent('SuiviLivraison', SuiviLivraison)
    .registerComponent('NumericTextBox', NumericTextBox)
    .registerComponent('NumericCheckBox', NumericCheckBox)
    .registerComponent('SelectTitleFromText', SelectTitleFromText)
    .registerComponent('SuggestedPrice', SuggestedPrice)
    .registerComponent('Soldes', Soldes)
    .registerComponent('TabNavigator', TabNavigator)
    .registerComponent('CBSkillSectionLink', CBSkillSectionLink)
    .registerComponent('DecimalSkillSectionLink', DecimalSkillSectionLink)
    .registerComponent('FileGrid', FileGrid)
    .registerComponent('FileGridRemoveCommand', FileGridRemoveCommand)
    .registerComponent('Uploader', Uploader)
    .registerComponent('FileGridEditCell', FileGridEditCell)
    .registerComponent('ToggleDescription', ToggleDescription)
    .registerComponent('Supplier', Supplier)
    .registerComponent('ActiveButton', ActiveButton)
    .registerComponent('KendoTabNavigator', KendoTabNavigator)
    .registerComponent('MeasurementDone', MeasurementDone)
    .registerComponent('ValidateMeasurement', ValidateMeasurement)
    .registerComponent('ModalClose', ModalClose)
    ;

import '@progress/kendo-ui/js/kendo.dropdownlist';
import '@progress/kendo-ui/js/kendo.combobox';
import '@progress/kendo-ui/js/kendo.upload';
import '@progress/kendo-ui/js/kendo.aspnetmvc';
import '@progress/kendo-ui/js/kendo.multiselect';
import '@progress/kendo-ui/js/kendo.datepicker';
import '@progress/kendo-ui/js/kendo.numerictextbox';
import '@progress/kendo-ui/js/kendo.grid';
import '@progress/kendo-ui/js/kendo.scheduler';
import '@progress/kendo-ui/js/kendo.dataviz.map';
import '@progress/kendo-ui/js/kendo.editor';
import '@progress/kendo-ui/js/kendo.window';
import '@progress/kendo-ui/js/kendo.tabstrip';
import '@progress/kendo-ui/js/kendo.dataviz.chart';

import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR';
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR';

import '@progress/kendo-ui/js/cultures/kendo.culture.fr-BE';
import '@progress/kendo-ui/js/messages/kendo.messages.fr-BE';

import '@progress/kendo-ui/js/cultures/kendo.culture.nl-BE';
import '@progress/kendo-ui/js/messages/kendo.messages.nl-BE';

import '@progress/kendo-ui/js/cultures/kendo.culture.en-GB';
import '@progress/kendo-ui/js/messages/kendo.messages.en-GB';

import '@progress/kendo-ui/js/cultures/kendo.culture.de-DE';
import '@progress/kendo-ui/js/messages/kendo.messages.de-DE';

import '@progress/kendo-ui/js/kendo.autocomplete';
import '@progress/kendo-ui/js/kendo.panelbar';

import '@progress/kendo-ui/js/kendo.timezones';

import 'leaflet/dist/leaflet.js';

$(function () {
    window.Kernel.mountComponents($('html'));
})
