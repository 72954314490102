export default class ExternalCalendar {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $(document).ready(function () {
            $(".linkCalendar").click(function (dropdown) {
                var uri = "";
                $.ajax({
                    url: $(dropdown.target).data("url"),
                    method: "POST",
                    data: {
                        idProjet: $(dropdown.target).data("projetid"),
                        typeDate: $(dropdown.target).data("typedate"),
                        target: $(dropdown.target).data("target"),
                        typeGeneration: $(dropdown.target).data("typegeneration")
                    },
                    async: false,
                    success: function (datas) {
                        if (datas.success == 1) {
                            if (datas.typeGeneration === "Url") {
                                uri = datas.urlCalendar;
                            }
                            else {
                                uri = "data:text/calendar; charset:UTF-8, " + encodeURI(datas.urlCalendar);
                            }
                            toastr.success(datas.message);
                        }
                        else {
                            toastr.error(datas.message);
                        }
                    },
                    error: function () {
                        toastr.error($(dropdown.target).data("error"));
                    }
                });
                if (uri != null) {
                    window.open(uri);
                }
            });
        });
    }
}