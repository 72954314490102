export default class Grid {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {

        $.fn.Grid = {
            onEdit: function (e) {
                let update = $(e.container).parent().find(".k-grid-update");
                let cancel = $(e.container).parent().find(".k-grid-cancel");
                $(update).html('<span class="k-icon k-update"></span><div class="icone" title="' + $.fn.Grid.GetTextButton(update) + '"><i class="fa fa-save btn green-brand-wipoz" aria-hidden="true"></i></div>');
                $(cancel).html('<span class="k-icon k-cancel"></span><div class="icone" title="' + $.fn.Grid.GetTextButton(cancel) + '"><i class="fa-solid fa-arrow-right-to-bracket btn blue-brand-wipoz" aria-hidden="true"></i></div>');
            },
            GetTextButton: function (e) {
                if ($(e).length > 0) {
                    return $(e)[0].innerText;
                }
                return "";
            },
            onError: function (e) {
                if (e.xhr.responseJSON
                    && e.xhr.responseJSON.message
                ) {
                    toastr.error(e.xhr.responseJSON.message);
                }
                else {
                    toastr.error(Resource.Error_Traitement);
                }
            }
        }
    }
}