export default class PublishActions {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        window.withPublish = false;
        $(document).ready(function () {
            $("#ModalCustom").addClass("modal-dialog-publish");
            $('#BoutonPublicationModal').click($.fn.PublishActions.validerPublication);
            $('#BoutonAnnulerModal').click($.fn.PublishActions.resetDialogStyle);
            $('#BoutonPublicationModal').attr('title', $('#BoutonPublicationModal').data('disabled-title'));

            $("input[name=swal2-radio]:radio").click($.fn.PublishActions.onRadioSelected)
            $('#RadioBtnReseauFavoris').click($.fn.PublishActions.SelectedPoseursFavoris);
            $('#RadioBtnReseauFavoris').click($.fn.PublishActions.SelectedNetwork);
            $('#RadioBtnReseauFavoris').click($.fn.PublishActions.SelectedMaSocietePose);
            $('#RadioBtnReseauFavoris').click($.fn.PublishActions.SelectedPoseursCircuitFerme);
            $.fn.PublishActions.displayMontantParts($.fn.PublishActions.GetChoice());
        });

        $.fn.PublishActions = {
            publicationValid : false,
            GetChoice: function () {
                var rates = document.getElementsByName('swal2-radio');
                for (var i = 0; i < rates.length; i++) {
                    if (rates[i].checked) {
                        return rates[i].value;
                    }
                }
            },

            onRadioSelected: function (e) {
                if (e.target.checked) {
                    $.fn.PublishActions.displayMontantParts(e.target.value);
                }
            },

            displayMontantParts: function (actionType) {
                $(".bloc-facture-detail").hide();
                $("#bloc-facture-detail-" + actionType).show();
            },

            validerPublication: function () {
                $.fn.PublishActions.resetDialogStyle();
                document.getElementById('ErrorMessage').innerHTML = "";
                $("#ErrorMessage").addClass("hidden");
                var ChoixPublication = $.fn.PublishActions.GetChoice()
                document.getElementById("ChoixPublication").value = ChoixPublication;
                $("#form-projet-Publication").submit();
                Swal.fire({
                    title: ResourcePublication.EnvoiDesNotifications,
                    allowOutsideClick: () => !Swal.isLoading()
                });
                Swal.showLoading();
            },

            // Sélection du radio bouton "Poseur favoris"
            SelectedPoseursFavoris: function () {
                $('#confirm-publish').trigger("change");
                if ($.fn.PublishActions.OnFavoritesChanged()) {
                    $('#DivPoseursFavorisInput').show();
                }
                else {
                    $('#DivPoseursFavorisInput').hide();
                }
            },

            SelectedNetwork: function () {
                $('#confirm-publish').trigger("change");
                var choice = $.fn.PublishActions.GetChoice();
                if (choice === "0") {
                    $("#BoutonPublicationModal").attr("disabled", "disabled");
                    if ($('#confirm-publish').is(':checked')) {
                        $("#BoutonPublicationModal").removeAttr("disabled");
                    }
                }
            },

            // Sélection du radio bouton "Société de pose"
            SelectedMaSocietePose: function () {
                $('#confirm-publish').trigger("change");
                var inputChecked = $.fn.PublishActions.GetChoice();
                if (inputChecked === "3") {
                    $('#DivMaSocietePoseInput').show();
                    $("#BoutonPublicationModal").text(ResourcePublication.Btn_Attribuer);
                    $("#BoutonPublicationModal").attr("disabled", "disabled");
                    if ($('#confirm-publish').is(':checked')) {
                        $("#BoutonPublicationModal").removeAttr("disabled");
                    }
                }
                else {
                    $('#DivMaSocietePoseInput').hide();
                    $("#BoutonPublicationModal").text(ResourcePublication.Btn_Publier);
                }
            },

            // Sélection du radio bouton "Poseur circuit fermé"
            SelectedPoseursCircuitFerme: function () {
                $('#confirm-publish').trigger("change");
                if ($.fn.PublishActions.OnPoseurCircuitFermeChanged()) {
                    $('#DivPoseursCircuitFermeInput').show();
                }
                else {
                    $('#DivPoseursCircuitFermeInput').hide();
                }
            },

            OnSuccessPublication: function (data) {
                if (data.Success === "true") {
                    $("#notificationReseauDateEnvoie").html("Publié le " + $.fn.PublishActions.GetCurrentDate());
                    $('#ModalCustom').modal('hide');
                    location.href = urlActionPublication.actionValiderFacturation + "?Wipoz_ProjetID=" + data.Wipoz_ProjetID;
                }
                else if (data.Success === "false" && data.errorDetail !== undefined) {
                    Swal.close();
                    var message = "<span class=\"k-icon k-i-warning\"> </span>\n " + data.errorDetail;
                    document.getElementById('ErrorMessage').innerHTML = message;
                    $("#ErrorMessage").removeClass("hidden");
                }
                else if (data.errorDetail !== undefined) {
                    Swal.close();
                    toastr.error(ResourcePublication.ImpossiblePublierProjet + " : " + data.errorDetail);
                }
                else {
                    location.reload();
                    toastr.error(ResourcePublication.ImpossiblePublierProjet);
                }
            },

            OnFailurePublication: function (response) {
                Swal.close();
                if (response.statusText) {
                    toastr.error(ResourcePublication.ImpossiblePublierProjet + " : " + response.statusText);
                }
                else {
                    toastr.error(ResourcePublication.ImpossiblePublierProjet);
                }
                return false;
            },

            OnFavoritesChanged: function () {
                var nbListePoseurs = 0;
                var multiselect = $("#SelectedFavorites").data("kendoMultiSelect");
                // get the value of the multiselect.
                if (multiselect !== undefined) {
                    nbListePoseurs = multiselect.value().length;
                }
                var choice = $.fn.PublishActions.GetChoice();
                if (choice === "1") {
                    $("#BoutonPublicationModal").attr("disabled", "disabled");
                    if ($('#confirm-publish').is(':checked') && (nbListePoseurs > 0)) {
                        $("#BoutonPublicationModal").removeAttr("disabled");
                    }
                }
                return choice === "1";
            },

            OnPoseurCircuitFermeChanged: function () {
                var nbListePoseurs = 0;
                var multiselect = $("#SelectedPoseurCircuitFerme").data("kendoMultiSelect");
                // get the value of the multiselect.
                if (multiselect !== undefined) {
                    nbListePoseurs = multiselect.value().length;
                }
                var choice = $.fn.PublishActions.GetChoice();
                if (choice === "4") {
                    $("#BoutonPublicationModal").attr("disabled", "disabled");
                    if ($('#confirm-publish').is(':checked') && (nbListePoseurs > 0)) {
                        $("#BoutonPublicationModal").removeAttr("disabled");
                    }

                }
                return choice === "4";
            },

            GetCurrentDate: function () {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth() + 1; //January is 0!
                var yyyy = today.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd
                }

                if (mm < 10) {
                    mm = '0' + mm
                }

                today = dd + '/' + mm + '/' + yyyy;
                return today;
            },
            resetDialogStyle: function () {
                $("#ModalCustom").removeClass("modal-dialog-publish");
            }
        };
    }
}