import Swal from "sweetalert2";

export default class CreationProjet {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {

        $.fn.createOrEdit = {
            urlDeleteFichier: "",
            wipozProjetID: "",
            deleteDocumentConfirmation: "",
            deleteDocumentErreur: "",
            saveProjetEtapeInfoGenerales: "",
            SaveProjetEtapePlanningEtBudget: "",
            saveProjetEtapeTechnique: "",
            saveProjetEtapeDocument: "",
            saveProjetEtapePresentationProjet: "",
            GetPartialViewUpdateProject: "",
            UrlGetNbPoseur: "",
            activateNext: function () {
                $("#applicationChangement").removeAttr("disabled");
                $("#applicationChangement").css('cursor', 'pointer');
                $("#applicationChangement").removeAttr('title');

                $("#etapeSuivanteValidForm").removeAttr("disabled");
                $("#etapeSuivanteValidForm").css('cursor', 'pointer');
                $("#etapeSuivanteValidForm").removeAttr('title');
            },
            initialize: function () {
                $.fn.createOrEdit.urlDeleteFichier = CreateOrEditURLs.Delete;
                $.fn.createOrEdit.wipozProjetID = $("#Wipoz_ProjetID").val();
                $.fn.createOrEdit.deleteDocumentConfirmation = Resource.DeleteDocument_Confirmation;
                $.fn.createOrEdit.deleteDocumentErreur = Resource.DeleteDocument_Erreur;
                $.fn.createOrEdit.urlEtapeInfoGenerales = CreateOrEditURLs.urlEtapeInfoGenerales;
                $.fn.createOrEdit.urlEtapeAdresse = CreateOrEditURLs.urlEtapeAdresse;
                $.fn.createOrEdit.urlEtapeInfoTechniques = CreateOrEditURLs.urlEtapeInfoTechniques;
                $.fn.createOrEdit.urlEtapeDocuments = CreateOrEditURLs.urlEtapeDocuments;
                $.fn.createOrEdit.GetPartialViewUpdateProject = CreateOrEditURLs.GetPartialViewUpdateProject;
                $.fn.createOrEdit.UrlGetNbPoseur = CreateOrEditURLs.GetNbPoseur;

                $(function () {
                    $.fn.createOrEdit.updateButton();
                    $.fn.createOrEdit.updateWizardClassActive($("#eTapeEnCours").val());

                    $("#etapeSuivanteValidForm").click(function (e) {
                        e.preventDefault();
                        var etapeSuivante = (parseInt($("#eTapeEnCours").val()) + 1);
                        $("#GoToEtape").val(etapeSuivante);
                        $("#IsEnregistrer").val("False");
                        if ($("#form-projet").data("kendoValidator").validate()) {
                            $.fn.createOrEdit.ShowLoadingCreateProjet();
                            $("#form-projet").submit();
                        }
                    });

                    $("#applicationChangement").click(function (e) {
                        e.preventDefault();
                        var etapeSuivante = (parseInt($("#eTapeEnCours").val()));
                        $("#GoToEtape").val(etapeSuivante);
                        $("#IsEnregistrer").val("True");

                        //submit déclenche la validation (appel de la méthode validate de kendoValidator)
                        //et si la validation échoue, ne fait pas l'appel à l'API (action du form)
                        //si la validation réussit, fait l'appel à l'API (action du form)
                        if ($("#form-projet").data("kendoValidator").validate()) {
                            $.fn.createOrEdit.ShowLoadingCreateProjet();
                            $("#form-projet").submit();
                        }
                    });

                    $("#etapePrecedenteForm").click(function (e) {
                        e.preventDefault();
                        var etapeSuivante = (parseInt($("#eTapeEnCours").val()) - 1);
                        $("#GoToEtape").val(etapeSuivante);
                        $("#IsEnregistrer").val("False");
                        if ($("#form-projet").data("kendoValidator").validate()) {
                            $.fn.createOrEdit.ShowLoadingCreateProjet();
                            $("#form-projet").submit();
                        }
                    });
                });
            },
            ShowLoadingCreateProjet: function () {
                Swal.fire({
                    title: ResourceCreationProjet.SauvegardeEnCours,
                    allowOutsideClick: () => !Swal.isLoading()
                });
                Swal.showLoading();
            },
            OnFailure: function () {
                Swal.close();
            },
            OnSuccess: function (data) {
                if (data.successEdit == 1) {
                    toastr.success(ResourceCreationProjet.ModificationsEffectuees);
                }
                else {
                    $.fn.createOrEdit.OnSuccessRefresh(data);
                }
                Swal.close();
            },
            OnSuccessRefresh: function (data) {
                if (data.success == 1) {
                    $.fn.createOrEdit.ShowContent(data.message);
                }
                else if (data.message != null
                    && data.message != undefined
                    && data.message != "") {

                    toastr.error(data.message);
                }
                else {
                    toastr.error(ResourceCreationProjet.ImpossibleEnregistrerProjet);
                }
            },
            ShowContent: function (html) {
                $("#partialFormCreateOrEditFor").html(html);
                window.Kernel.mountComponents($('#partialFormCreateOrEditFor'));
                $.fn.createOrEdit.updateWizardClassActive($("#eTapeEnCours").val());
                $.fn.createOrEdit.updateButton();
                setTimeout(function () {
                    $(document).scrollTop(0);
                }, 500);
            },
            OnSuccessEtapeInfoGenerale: function (data) {
                if (data.successEdit == 1) {
                    $("#Wipoz_ProjetID").val(data.Wipoz_ProjetID);
                    toastr.success(ResourceCreationProjet.ModificationsEffectuees);
                    $.fn.createOrEdit.updateButton();
                }
                else {
                    $.fn.createOrEdit.OnSuccessRefresh(data);
                    if (data.success == 0) {
                        // Pour actualiser les messages d'erreur
                        var validator = $("#form-projet").data("kendoValidator");
                        validator.validate();
                    }
                }
                Swal.close();
            },
            OnFailureEtapeInfoGenerale: function () {
                Swal.close();
                toastr.error(ResourceCreationProjet.ImpossibleEnregistrerProjet);
            },
            OnSuccessEtapeManagement: function (data) {
                $.fn.createOrEdit.OnSuccess(data);
            },
            OnFailureEtapeManagement: function () {
                Swal.close();
                toastr.error(ResourceCreationProjet.ImpossibleEnregistrerProjet);
            },
            OnSuccessEtapeAdresse: function (data) {
                if (data.successEdit == 1) {
                    $("#IdTemp").val(data.IdTemp);
                    toastr.success(ResourceCreationProjet.ModificationsEffectuees);
                }
                else if (data.successEdit == 0) {
                    Swal.fire({
                        type: 'warning',
                        title: data.titre,
                        text: data.message,
                        customClass: {
                            confirmButton: 'btnValiderSwalCustom'
                        }
                    });
                    var numerictextbox = $("#BudgetEstime").data("kendoNumericTextBox");
                    numerictextbox.value(data.budget);
                }
                else {
                    $.fn.createOrEdit.OnSuccessRefresh(data);
                }
                Swal.close();
            },
            OnFailureEtapeAdresse: function () {
                Swal.close();
                toastr.error(ResourceCreationProjet.ImpossibleEnregistrerProjet);
            },
            OnSuccessEtapePresentationTechnique: function (data) {
                $.fn.createOrEdit.OnSuccess(data);
            },
            OnFailureEtapePresentationTechnique: function () {
                Swal.close();
                toastr.error(ResourceCreationProjet.ImpossibleEnregistrerProjet);
            },

            OnSuccessEtapeDocument: function (data) {
                var url = "/Projet/Fiche?Wipoz_ProjetID=" + data.Wipoz_ProjetID;
                if (data.successEdit == -1) {
                    Swal.fire({
                        title: ResourceCreationProjet.ImagePrincipaleObligatoire,
                        text: ResourceCreationProjet.ImagePrincipaleObligatoire_Texte,
                        type: 'error',
                        confirmButtonColor: '#2ABFBA'
                    });
                }
                else if (data.successEdit == 1) {
                    toastr.success(ResourceCreationProjet.ModificationsEffectuees);
                    window.location.href = url;
                }
                else if (data.successEdit == 2) {
                    Swal.fire({
                        title: ResourceCreationProjet.ModificationDocumentTitre,
                        text: ResourceCreationProjet.ModificationDocumentText,
                        type: 'warning',
                        confirmButtonColor: '#2ABFBA',
                        confirmButtonText: ResourceCreationProjet.LBL_OK,
                        showLoaderOnConfirm: true
                    }).then((result) => {
                        window.location.href = url;
                    });
                }
                else if (data.successExit == 1) {
                    //mise à jour de tous les uploader
                    var nbDocType = $(".row .ajout-document").length;
                    var queries = [];
                    for (var i = 0; i < nbDocType; i++) {
                        var asyncRequest = $.fn.createOrEditDocument.loadDocument(true, $("[name=\"" + $.escapeSelector("Documents[" + i + "].DocumentType") + "\"]").val(), i);
                        queries.push(asyncRequest);
                    }
                    if (withPublish) {
                        $.when(queries).done(function () {
                            Swal.fire({
                                title: ResourceCreationProjet.LBL_ChargementInformationsPublication,
                                allowOutsideClick: false,
                                showCloseButton: false,
                                showCancelButton: false,
                                onAfterClose: function () {
                                    $(window).scrollTop(0);
                                }
                            });
                            Swal.showLoading();
                            $("#ModalCustom .modal-body").html("");
                            $.ajax({
                                url: CreateOrEditURLs.GetModalPublication,
                                method: "POST",
                                async: false,
                                data: { wipoz_ProjetID: data.Wipoz_ProjetID },
                                success: function (html) {
                                    Swal.close();
                                    $("#ModalCustom .modal-dialog").addClass(html.stylelayout);
                                    $("#ModalCustom .modal-body").html(html.content);
                                    $("#ModalCustom .modal-header").hide();
                                    $("#ModalCustom .modal-footer").hide();
                                    window.Kernel.mountComponents($('#ModalCustom'));
                                    $("#ModalCustom").modal({ backdrop: 'static', keyboard: false, focus: true, show: true });
                                },
                                error: function () {
                                    toastr.error(ResourceCreationProjet.PublicationProjetImpossible);
                                    Swal.close();
                                }
                            });
                        });
                    }
                    else {
                        window.location.href = url;
                    }
                }
                else {
                    $.fn.createOrEdit.OnSuccessRefresh(data);
                    Swal.close();
                }
            },

            OnFailureEtapeDocument: function () {
                Swal.close();
                toastr.error(ResourceCreationProjet.ImpossibleEnregistrerProjet);
            },

            updateWizardClassActive: function (etapeActive) {
                var etapeValide = ($("#CreationProjetEtape").val());
                var etapeValidSuivante = (parseInt(etapeValide) + 1);
                var projetId = $("#Wipoz_ProjetID").val();
                var ModeEdition = $("#ModeEdition").val();
                $("#wizard .stepbar-nav-item a").each(function () {
                    var element = $(this);
                    var step = parseInt(element.data("step"));
                    if (etapeActive == step) {
                        element.removeClass();
                        element.addClass("active");
                        element.css('cursor', 'pointer');
                    }
                    else if (step <= etapeValide) {
                        element.removeClass();
                        element.addClass("valide");
                        element.attr("onclick", "$.fn.createOrEdit.goToEtape('" + projetId + "'," + step + "," + ModeEdition + ")");
                        element.css('cursor', 'pointer');
                    }
                    else if (step == (etapeValidSuivante)) {
                        element.removeClass();
                        element.addClass("disabled");
                        element.css('cursor', 'pointer');
                        element.attr("onclick", "$.fn.createOrEdit.goToEtape('" + projetId + "'," + step + "," + ModeEdition + ")");
                    }
                    else {
                        element.removeClass();
                        element.addClass("disabled");
                        element.css('cursor', 'not-allowed');
                    }
                });
                
            },

            updateButton: function () {
                $("#etapePrecedenteForm").show();
                $("#applicationChangement").show();
                $("#etapeSuivanteValidForm").html(ResourceCreationProjet.BoutonSuivant);
                $("#etapeSuivanteValidForm").show();
                $("#ConfirmPublishCreate").hide();
                
                switch (parseInt($("#eTapeEnCours").val())) {
                    case ongletEtape.management:
                        if (!$("#Wipoz_ProjetID").val()) {
                            $("#applicationChangement").hide();
                        }
                        $("#etapePrecedenteForm").hide();
                        break;
                    case ongletEtape.infogenerale:
                        if (!$("#Wipoz_ProjetID").val()
                            || $("#Wipoz_ProjetID").val() == '00000000-0000-0000-0000-000000000000') {
                            $("#etapePrecedenteForm").hide();
                        }
                        break;
                    case ongletEtape.documents:
                        $("#etapeSuivanteValidForm").hide();
                        if ($("#DisplayPublishbutton").val() == "True") {
                            $("#ConfirmPublishCreate").show();
                        }
                        if ($("#ModeEdition").val() == ModeEdition.Confirmation) {
                            $("#ConfirmMeasurement").removeClass("d-none");
                        }
                        break;
                    default:
                        break;
                }
            },
            goToEtape: function (Wipoz_ProjetID, etape, ModeEdition) {
                $.fn.createOrEdit.PostAjaxPartialViewForm($.fn.createOrEdit.GetPartialViewUpdateProject, Wipoz_ProjetID, etape, ModeEdition);
            },
            PostAjaxPartialViewForm: function (url, Wipoz_ProjetID, etape, ModeEdition) {
                Swal.fire({
                    title: Resource.Patienter,
                    allowOutsideClick: () => !Swal.isLoading()
                });
                Swal.showLoading();
                $("main-wrapper").css('cursor', 'wait');
                $.ajax({
                    url: url,
                    method: "POST",
                    data: {
                        Wipoz_ProjetID: Wipoz_ProjetID,
                        etape: etape,
                        ModeEdition: ModeEdition,
                    },
                    success: function (datas) {
                        $(".main-wrapper").css('cursor', '');
                        if (datas.success == 1) {
                            $.fn.createOrEdit.ShowContent(datas.message);
                        }
                        else {
                            toastr.error(ResourceCreationProjet.ErreurSurvenue);
                        }
                        Swal.close();
                    },
                    error: function () {
                        $(".main-wrapper").css('cursor', '');
                        toastr.error(ResourceCreationProjet.ErreurSurvenue);
                        Swal.close();
                    }
                });
            },

            //Image principale : Devrait être dans createOrEditDocument, néanmoins, le chargement kendo a besoin que les méthodes JS existent dès le chargement.
            // TOOPTIMIZE : Ajouter un deferred load pour le js de l'upload pour replacer ce module correctement
            ImageUpload: function (e) {
                $('#UploadImageProjet').val("");
            },

            RemoveUpload: function () {
                $('#UploadImageProjet').val("");
            },

            OnSuccessUpload: function (e) {
                if (e.response.success == 1) {
                    $('#UploadImageProjet').val("ok");
                    $('#DocumentLibelle').val(e.response.filename);
                    $('#Image').val(e.response.filename);
                }
            },
        };

        $.fn.createOrEdit.initialize();
    }
}