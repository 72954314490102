export default class SuggestedPrice {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind($view) {
        var target = $($view).data("priceTarget");
        $.fn.SuggestedPrice = {
            apply: function () {
                window.event.preventDefault();
                $('#ModalContent').modal('hide');

                var price = $($view).data("price");
                
                var knumericInput = $("#" + target).data("kendoNumericTextBox");
                knumericInput.value(price);
                knumericInput.trigger("change");
                window.kendoValidator.validate();
            },

            setDetails: function () {
                window.event.preventDefault();
                var editUrl = $($view).data("editUrl");
                var showDetailUrl = $($view).data("showdetailUrl");
                $.ajax({
                    url: showDetailUrl,
                    method: "POST",
                    success: function () {
                        window.location.assign(editUrl);
                    },
                    error: function () {
                    }
                });


            }
        }
    };
}