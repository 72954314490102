export default class ValidationUpdateProjet {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {
        $(document).ready(function () {
            const msgDateMetreObligatoire = $("#DateDeMetre").data('isrequiredsimetrearealiser');
            const msgDateFinMetreObligatoire = $("#DateFinDeMetre").data('isrequiredsimetrearealiser');

            $('#BoutonValiderUpdateModal').click($.fn.ValidationUpdateProjet.validerModificationProjet);

            $('.btnAnnulerSwalCustom').on('click', () => {
                $('#ModalCustom').modal('hide');
            });

            $('#CheckboxAverti').on('click', (e) => {
                $('#errorMsgCheckboxAverti').html("");
            });

            let formProjet = $("#form-projet-ValidationUpdate");
            kendo.init(formProjet);
            window.kendoValidator = formProjet.kendoValidator({
                validate: function () {
                    $(".k-invalid:first").focus();
                    if ($(".k-invalid:first").data("kendoNumericTextBox")) {
                        $(".k-invalid:first").data("kendoNumericTextBox").focus();
                    }
                },
                rules: {
                    issmallerdatethandatelivraison: function (input) {
                        if (input.is("[data-issmallerdatethandatelivraison-msg]") && input.val() != "") {
                            var date = kendo.parseDate(input.val()),
                                otherDate = kendo.parseDate($("[name='" + input.data("issmallerdatethandatelivraisonField") + "']").val());
                            return otherDate == null || date.getTime() < otherDate.getTime();
                        }
                        return true;
                    },
                    issmallerdatethandatepose: function (input) {
                        if (input.is("[data-issmallerdatethandatepose-msg]") && input.val() != "") {
                            var date = kendo.parseDate(input.val()),
                                otherDate = kendo.parseDate($("[name='" + input.data("issmallerdatethandateposeField") + "']").val());
                            return otherDate == null || date.getTime() < otherDate.getTime();
                        }
                        return true;
                    },
                    issmallerorequaldatethan: function (input) {
                        if (input.is("[data-issmallerorequaldatethan-msg]") && input.val() != "") {
                            var date = kendo.parseDate(input.val()),
                                otherDate = kendo.parseDate($("[name='" + input.data("issmallerorequaldatethanField") + "']").val());
                            return otherDate == null || date.getTime() <= otherDate.getTime();
                        }

                        return true;
                    },
                    issmallerdatethanDateFinDeMetre: function (input) {
                        if (input.is("[data-issmallerdatethanDateFinDeMetre-msg]") && input.val() != "") {
                            var date = kendo.parseDate(input.val()),
                                otherDate = kendo.parseDate($("[name='DateFinDeMetre']").val());
                            return otherDate == null || date.getTime() <= otherDate.getTime();
                        }

                        return true;
                    },
                    isBiggerdatethanDateDeMetre: function (input) {
                        if (input.is("[data-isbiggerdatethanDateDeMetre-msg]") && input.val() != "") {
                            var date = kendo.parseDate(input.val()),
                                otherDate = kendo.parseDate($("[name='DateDeMetre']").val());
                            return otherDate == null || date.getTime() >= otherDate.getTime();
                        }

                        return true;
                    },
                    range: function (input) {
                        if ($(input).is("[data-role=SoldeValue]")
                            && $(input).attr('data-val-required')) {

                            var min = parseFloat($(input).data("valRangeMin"), 2);
                            var value = parseFloat($(input).val());

                            if (isNaN(min)) {
                                return true;
                            }
                            if (isNaN(value)) {
                                return false;
                            }
                            // La valeur min n'est pas autorisée
                            return min < value;
                        }
                        return true;
                    }
                },
            }).data("kendoValidator");

            var value = $("#ManagementType").data("value");
            if (!(value & managementType.wipoz) && (value & managementType.delivery)) {
                $("#DateDeLivraisonModal").removeAttr("data-val-required");
            }

            $("#WeekDateDebut").text($.fn.ValidationUpdateProjet.getNumWeek($("#DateDebut").val()));
            $("#WeekDateDeLivraison").text($.fn.ValidationUpdateProjet.getNumWeek($("#DateDeLivraisonModal").val()));

            $("#DateDebut").change(function () {
                $("#WeekDateDebut").text($.fn.ValidationUpdateProjet.getNumWeek($("#DateDebut").val()));
                window.kendoValidator.validate();
            });

            $("#DateDeLivraisonModal").change(function () {
                $("#WeekDateDeLivraison").text($.fn.ValidationUpdateProjet.getNumWeek($("#DateDeLivraisonModal").val()));
                window.kendoValidator.validate();
            });


            $("#SoldeFinChantier").change(function () {
                window.kendoValidator.validate();
            });

            $("#SoldeFinChantier").blur(function () {
                window.kendoValidator.validate();
            });

            if ($("#FinChantierARecuperer2").is(":checked") == true) {
                $("#SoldeFinChantierForm").find("span").attr('disabled', 'disabled');
                $("#SoldeFinChantier").removeAttr("data-val-required");
                $("#SoldeFinChantier").attr('disabled', 'disabled');
            }
            else {
                $("#SoldeFinChantierForm").find("span").removeAttr('disabled');
                $("#SoldeFinChantierForm").find("span[data-for='SoldeFinChantier']").hide();
                $("#SoldeFinChantier").attr("data-val-required", $("#SoldeFinChantier").data('data_isrequiredsoldefinchantier'));
                $("#SoldeFinChantier").removeAttr('disabled');
            }

            $("#FinChantierARecuperer1").on("click", function () {
                if ($(this).is(":checked") == true) {
                    $("#SoldeFinChantierForm").find("span").removeAttr('disabled');
                    $("#SoldeFinChantier").attr("data-val-required", $("#SoldeFinChantier").data('isrequiredsoldefinchantier'));
                    $("#SoldeFinChantier").removeAttr('disabled');
                    window.kendoValidator.validate();
                }
            });

            $("#FinChantierARecuperer2").on("click", function () {
                if ($(this).is(":checked") == true) {
                    $("#SoldeFinChantierForm").find("span").attr('disabled', 'disabled');
                    $("#SoldeFinChantierForm").find("span[data-for='SoldeFinChantier']").hide();
                    var numerictextbox = $("#SoldeFinChantier").data("kendoNumericTextBox");
                    numerictextbox.value(0);
                    $("#SoldeFinChantier").removeAttr("data-val-required");
                    $("#SoldeFinChantier").attr('disabled', 'disabled');
                    window.kendoValidator.validate();
                }
            });

            $("#SoldeLivraison").change(function () {
                window.kendoValidator.validate();
            });

            $("#SoldeLivraison").blur(function () {
                window.kendoValidator.validate();
            });

            if ($("#LivraisonARecuperer2").is(":checked") == true) {
                $("#SoldeLivraisonForm").find("span").attr('disabled', 'disabled');
                $("#SoldeLivraisonForm").find("span[data-for='SoldeLivraison']").hide();
                $("#SoldeLivraison").removeAttr("data-val-required");
                $("#SoldeLivraison").attr('disabled', 'disabled');
            }
            else {
                $("#SoldeLivraisonForm").find("span").removeAttr('disabled');
                $("#SoldeLivraison").attr("data-val-required", $("#SoldeLivraison").data('data_isrequiredsoldelivraison'));
                $("#SoldeLivraison").removeAttr('disabled');
            }

            $("#LivraisonARecuperer1").on("click", function () {
                if ($(this).is(":checked") == true) {
                    $("#SoldeLivraisonForm").find("span").removeAttr('disabled');
                    $("#SoldeLivraison").attr("data-val-required", $("#SoldeLivraison").data('isrequiredsoldelivraison'));
                    $("#SoldeLivraison").removeAttr('disabled');
                    window.kendoValidator.validate();
                }
            });

            $("#LivraisonARecuperer2").on("click", function () {
                if ($(this).is(":checked") == true) {
                    $("#SoldeLivraisonForm").find("span").attr('disabled', 'disabled');
                    $("#SoldeLivraisonForm").find("span[data-for='SoldeLivraison']").hide();
                    var numerictextbox = $("#SoldeLivraison").data("kendoNumericTextBox");
                    numerictextbox.value(0);
                    $("#SoldeLivraison").removeAttr("data-val-required");
                    $("#SoldeLivraison").attr('disabled', 'disabled');
                    window.kendoValidator.validate();
                }
            });
        });

        $.fn.ValidationUpdateProjet = {
            validerModificationProjet: function () {
                if ($(this.view).data("checkobligatoire")) {
                    var IsCheckedCheckboxAverti = document.getElementById('CheckboxAverti').checked;
                    if (IsCheckedCheckboxAverti) {
                        $("#form-projet-ValidationUpdate").submit();
                    }
                    else {
                        $('#errorMsgCheckboxAverti').html(Resource.ValidationUpdateProjetChecboxObligatoire);
                    }
                }
                else {
                    Swal.fire({
                        title: ResourceFiche.Patienter,
                        allowOutsideClick: () => !Swal.isLoading(),
                        onAfterClose: function () {
                            $(window).scrollTop(0);
                        }
                    });
                    Swal.showLoading();
                    $("#form-projet-ValidationUpdate").submit();
                }
            },

            OnSuccessValidationUpdateProjet: function (data) {
                if (data.success == 1) {
                    window.location.reload();
                    toastr.success(Resource.ValidationUpdateProjetSuccess);
                }
                else if (data.success == 0) {
                    Swal.close();
                    Swal.fire({
                        type: 'warning',
                        title: data.titre,
                        text: data.message,
                        customClass: {
                            confirmButton: 'btnValiderSwalCustom'
                        }
                    });
                    var numerictextbox = $("#BudgetEstime").data("kendoNumericTextBox");
                    numerictextbox.value(data.budget);
                }
            },

            OnFailureValidationUpdateProjet: function () {
                Swal.close();
                toastr.error(Resource.ValidationUpdateProjetFailure);
            },

            getNumWeek: function (dateInput) {
                var result;
                if (dateInput) {
                    var parts = dateInput.split("/");
                    var d = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
                    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
                    // Set to nearest Thursday: current date + 4 - current day number
                    // Make Sunday's day number 7
                    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
                    // Get first day of year
                    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
                    // Calculate full weeks to nearest Thursday
                    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
                    // Return array of year and week number 
                    result = Resource.SemaineNumero + " " + weekNo;
                } else {
                    result = "";
                }
                return result;
            }
        };
    };
}