export default class SuspendreProjet {
    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {

        $(document).ready(function () {
            $("#CheckboxSuspension").on('change', function () {
                if ($(this).is(':checked')) {
                    $(".btnValiderSwalCustom").removeAttr("disabled");
                }
                else {
                    $(".btnValiderSwalCustom").attr("disabled", "disabled");
                }
            });

            $(".btnValiderSwalCustom").click($.fn.SuspendreProjet.suspendreProjet);

            $('.btnAnnulerSwalCustom').on('click', () => {
                $('#ModalCustom').modal('hide');
            })

            $("#ChoixMotifSuspension").removeAttr("data-val-number");

            let formProjet = $("#form-modal-suspendre-projet");
            kendo.init(formProjet);
            window.kendoValidator = formProjet.kendoValidator({
                validate: function (ev) {
                    if (ev.valid) {
                        Swal.fire({
                            title: ResourceSuspendre.SuspensionEnCours,
                            allowOutsideClick: () => !Swal.isLoading()
                        });
                        Swal.showLoading();
                    } else {
                        $(".k-invalid:first").focus();
                    }
                },
                rules: {

                }
            }).data("kendoValidator");
        });
        $.fn.SuspendreProjet = {
            suspendreProjet: function () {
                $("#form-modal-suspendre-projet").submit();
            },

            onSuccessSuspendreProjet: function (data) {
                Swal.close();
                $('#ModalCustom').modal('hide');
                if (data.success === 0) {
                    window.location.reload();
                    toastr.success(data.message);
                }
                else {
                    toastr.error(data.message);
                }
            },

            onFailureSuspendreProjet: function (data) {
                Swal.close();
                $('#ModalCustom').modal('hide');
                toastr.error(data.message);
            }
        };
    };
}