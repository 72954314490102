export default class createOrEditManagement {

    constructor($view) {
        this.view = $view;
        this.bind();
    }

    bind() {
        $.fn.createOrEdit.activateNext();

        $("#form-projet").kendoValidator({
            validate: function (ev) {
                $(".k-invalid:first").focus();
            }
        });


        $(document).ready(function () {
            $("input[name=ManagementType]").click(function (e) {
                $('#ManagementType_validationMessage').addClass("hidden");
            })
        });

        $.fn.createOrEditManagement = {
            OnBeginEtapeManagement: function () {
                var NoError = true;
                if ($('input[name=ManagementType]:checked').val() == null
                    || $('input[name=ManagementType]:checked').val() == "") {
                    $('#ManagementType_validationMessage').removeClass("hidden");
                    NoError = false;
                }
                if (!NoError) {
                    Swal.close();
                }
                return NoError;
            },
        };
    }
}