import Swal from "sweetalert2";

export default ($view) => {

    $view.on("click", (event) => {
        if ($($view).attr("disabled")) {
            event.preventDefault();
            return;
        }
        //stop la propagation de l'évènement click
        var title = $view.data("loading-message");
        if (title === null || title === undefined || title === '') {
            title = Resource.Patienter; 
        }
        var modalBackdrop = $view.data("modal-backdrop");
        if (modalBackdrop === null || modalBackdrop === undefined || modalBackdrop === '') {
            modalBackdrop = false;
        }
        Swal.fire({
            title: title,
            allowOutsideClick: () => !Swal.isLoading(),
            onAfterClose: function () {
                $(window).scrollTop(0);
            }
        });
        Swal.showLoading();
        event.preventDefault();
        //clear body before 
        $("#ModalCustom .modal-body").html("");
        $.ajax({
            url: $view.data('url'),
            method: $view.data('method') ? $view.data('method') : "POST",
            data: $view.data('parameters') ? $view.data('parameters') : {},
            success: function (data) {
                Swal.close();
                let $modal = $("#ModalCustom");
                let $btnConfirm = $modal.find('.modal-confirm');
                $modal.find(".modal-dialog").addClass(data.stylelayout);
                $modal.find(".modal-body").html(data.content);
                window.Kernel.mountComponents($($modal));
                
                $($modal).modal({
                    backdrop: modalBackdrop,
                    keyboard: true,
                    show: true,
                });
                if ((data.confirmLabel != undefined) && (data.confirmLabel != '')) {
                    $modal.find(".modal-confirm").show();
                    //decharge les event click sur le bouton de confirmation
                    $btnConfirm.unbind("click");
                    //distribuer l'événement.
                    $btnConfirm.on("click", function () {
                        //console.log($view);
                        $view.trigger('modalAjaxConfirm');
                    });
                }
                else {
                    $modal.find(".modal-confirm").hide();
                }
            },
            error: function (e) {
                Swal.close();
                switch (e.status) {
                    case 403:
                        if (e.responseJSON.url) {
                            window.location.assign(e.responseJSON.url);
                        }
                        else if (e.xhr.responseJSON.message) {
                            toastr.error(e.responseJSON.message);
                        }
                        break;
                    default:
                        if (e.responseJSON
                            && e.responseJSON.message) {
                            toastr.error(e.responseJSON.message);
                        }
                        else {
                            toastr.error(Resource.ErrorLoadingModal);
                        }
                        break;
                }
            }
        });
    });
} 